/* Mixins */
.project-select-component .project-item-wrapper {
  display: inline-block;
  margin: 10px;
  vertical-align: top;
}
.project-select-component .project-item-wrapper .project-item-card {
  width: 300px;
}
.project-select-component .project-item-wrapper .project-item-card .ant-card-cover {
  height: 160px;
  overflow: hidden;
}
.project-select-component .project-item-wrapper .project-item-card .ant-card-body {
  height: 54px;
  z-index: 1;
}
.project-select-component .project-item-wrapper .project-item-card .project-item-img {
  width: 100%;
}